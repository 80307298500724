import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Router } from 'preact-router';
import data from './data.json';

console.log(data[0]);

// Run in browser!
function update() {
	var x = [];
	$('dl.method').each((i, e) => {
		x.push({
			prename: $(e).find('.sig-prename').first().text(),
			name: $(e).find('.sig-name').first().text(),
			desc: $(e).find('dd').first().html()
		});
	});
	console.log(JSON.stringify(x));
}

const App = () => {
	const [card, setCard] = useState(data[(Math.random() * data.length) | 0]);

	return (
		<div class="verbs-shuffle__content">
			<div class="wrapper">
				<p class="verbs-shuffle__verb"><span class="sm">{card.prename}</span>{card.name}</p>
				<div class="verbs-shuffle__information-content" dangerouslySetInnerHTML={{ __html: card.desc }}>

				</div>
			</div>

			<span>
				<button class="verbs-shuffle__shuffle-button"><img alt="Swap icon" class="verbs-shuffle__shuffle-icon" src="https://img.icons8.com/dotty/42327/literature.png" lazy="loaded" /></button>
				<button onClick={() => setCard(data[(Math.random() * data.length) | 0])} class="verbs-shuffle__shuffle-button"><img alt="Swap icon" class="verbs-shuffle__shuffle-icon" src="https://img.icons8.com/dotty/344/swap.png" lazy="loaded" /></button>
			</span>

			<br /><br />

			<a href="" class="verbs-shuffle__verb-link" target="_blank">Want to learn more?</a>
		</div>
	);
}

export default App;
